function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface ImenuItem {
  href?: string;
  name: string;
  bg: string;
}

const menuItem = (props: ImenuItem) => {
  return (
    <a
      href={props.href}
      className={classNames(
        props.bg == "dark"
          ? "text-white"
          : "text-black-900 hover:text-gray-900",
        " text-base font-medium  focus:outline-none"
      )}
    >
      {props.name}
    </a>
  );
};

export default menuItem;
