import { UilPhoneVolume } from '@iconscout/react-unicons'

interface ImobileFooter {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const mobileFooter = (props: ImobileFooter) => {
  return (
    <>
      <a className="whitespace-nowrap text-base font-medium text-black-500 hover:text-black-900" href='tel:08503466600'>
        <UilPhoneVolume className="inline w-4 " /> 0850 346 66 00
      </a>
      <button
        className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-sm font-400 text-white bg-orange-600 hover:bg-orange-700"
        onClick={() => props.setOpen?.(true)}
      >
        Ücretsiz Deneyin
      </button>
    </>
  );
};

export default mobileFooter;
