interface ImobileLinks {
  links: {
    name: string;
    href: string;
  }[];
  other: {
    name: string;
    href: string;
  };
}
const mobileLinks = (props: ImobileLinks) => {
  return (
    <div className="py-6 px-5 space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-y-4 gap-x-8">
        <a
          key={props.other.name}
          href={props.other.href}
          className="text-sm font-medium text-gray-900 hover:text-gray-700"
        >
          {props.other.name}
        </a>

        {props.links.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className="text-sm font-medium text-gray-900 hover:text-gray-700"
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default mobileLinks;
