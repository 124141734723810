/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationIcon } from '@heroicons/react/outline'

import { UilTimes } from '@iconscout/react-unicons'
interface IPopup{
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  cancelButtonRef?: React.MutableRefObject<null>,
  children: React.ReactNode
}
const popup = ({open, setOpen, cancelButtonRef, children}:IPopup) => {
  

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
            
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
          
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                
              <Dialog.Panel className="bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 w-[350px] md:w-[650px] relative">
              <button className='absolute top-[-10px] right-[-10px] shadow-md rounded-full bg-white w-6 h-6 text-center' onClick={() => setOpen(false)}>
                <UilTimes className="text-black w-4 mx-auto" />
              </button>
                
                
                {children}
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


export default popup