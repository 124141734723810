import Head from "next/head";
interface Imeta {
  title: string;
  description: string;
}
const meta = (props: Imeta) => {
  return (
    <Head>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <link rel="icon" href="./favicon.svg" type="image/svg+xml"></link>
    </Head>
  );
};

export default meta;
