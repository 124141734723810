import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface ImenuWithSubMenu {
  item: {
    name: string;
    childs: {
      name: string;
      description: string;
      href: string;
    }[];
  };
  bg: string;
}

const menuWithSubMenu = (props: ImenuWithSubMenu) => {
  const item = props.item;
  return (
    <>
      <Popover className="relative" key={item.name + "eticex"}>
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? "text-orange-600" : "text-black-500",
                "group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none"
              )}
            >
              <span
                className={classNames(
                  props.bg == "dark" ? "text-white" : "",
                  ""
                )}
              >
                {item.name}
              </span>

              <ChevronDownIcon
                className={classNames(
                  open
                    ? props.bg == "dark"
                      ? "text-white"
                      : "text-black-600"
                    : props.bg == "dark"
                    ? "text-white"
                    : "text-black-400",
                  "ml-2 h-5 w-5 group-hover:text-gray-500 text-xs"
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10  mt-3  transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-40 lg:-translate-x-1/2">
                <div className="border-solid z-10 border-l-white border-l-8 border-y-transparent border-y-8 border-r-0 mb-0"></div>
                <div className="relative z-20 grid  -mt-2 bg-white rounded-md shadow-lg overflow-hidden max-w-max">
                  {item.childs.map((subItem, index) => {
                    return (
                      <Link
                        href={subItem.href}
                        key={index + "subitem"}
                        className="items-start border-b last:border-b-0 border-gray-100 px-4 py-2 hover:bg-orange-100"
                      >
                        
                          <span className="text-base font-medium text-gray-800 block">
                            {subItem.name}
                          </span>
                          {subItem.description && (
                            <span className="text-xs font-light text-gray-500">
                              {subItem.description}
                            </span>
                          )}
                        
                      </Link>
                    );
                  })}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default menuWithSubMenu;
