import Image from "next/image";
import { useState } from "react";
import { sendData } from "../../utilities/fetch";
import { ValidateEmail, ValidatePhoneNumber } from "../../utilities/validation";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { getCookie } from 'cookies-next';

interface IContent {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cancelButtonRef: React.MutableRefObject<null>;
  description?: string
}
const Content = (props: IContent) => {
  const [error, setError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>(
    "Lütfen tüm alanları doldurduğunuzdan emin olun !"
  );
  const [success, setSuccess] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const formData = {
      name: name,
      email: email,
      phonenumber: phonenumber,
      company: company,
      source: 7,
      description: props.description ? props.description : "Demo Formu - popup",
      utm_campaign: getCookie('utm_campaign', {}),
      utm_date: getCookie('utm_date', {}),
      utm_url: getCookie('utm_uri', {}),
    };


    if (
      name.length < 1 ||
      email.length < 1 ||
      phonenumber.length < 1 ||
      company.length < 1
    ) {
      setError(true);
      setErrMsg("Lütfen tüm alanları doldurduğunuzdan emin olun !");
    } else {
      if (ValidateEmail(email)) {
        if (ValidatePhoneNumber(phonenumber)) {
          const ret = await sendData("/leads", formData);

          if (ret.status != 200) {
            setError(true);
            setSuccess(false);
          } else {
            setError(false);
            setSuccess(true);
            //window.location.replace('/tesekkurler.html');
          }
        } else {
          setError(true);
          setErrMsg("Telefon numaranızı hatalı girdiniz");
        }
      } else {
        setError(true);
        setErrMsg("Email adresiniz hatalı girdiniz");
      }
    }
  };
  return (
    <div className="grid grid-cols1 md:grid-cols-2">
      <div className="hidden md:block bg-blue-1000 p-8 text-white w-80 rounded-l-lg ">
        <h3 className="font-semibold mb-6">
          Karar vermekde zorluk mu yaşıyorsunuz?
        </h3>
        <Image
          src="https://cdn.eticex.com/e-ticaret/img/popup/1.png"
          alt="bilgi almak istiyorum"
          width="280"
          height="280"
        />
      </div>
      <form className="p-3" onSubmit={handleSubmit}>
        {!success && (
          <>
            <h3 className="font-semibold text-base mt-4">Bilgi istek formu</h3>
            <p className="text-xs font-light leading-6">
              Formu hemen doldurun uzmanlarımız sizi arasın!
            </p>
            <input
              type="text"
              className="mt-6 border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Adınız Soyadınız"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="E-Posta Adresiniz"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Telefon numaranız"
              onChange={(e) => setPhonenumber(e.target.value)}
            />
            <input
              type="text"
              className="border border-gray-200 px-2 py-2 rounded mb-4 w-full text-sm font-light"
              placeholder="Firma Ünvanı"
              onChange={(e) => setCompany(e.target.value)}
            />

            <button
              className="mt-3 rounded-md bg-orange-500 hover:bg-orange-700 text-white font-semibold text-sm w-full text-center py-2"
              type="submit"
              ref={props.cancelButtonRef}
            >
              Bilgi almak istiyorum
            </button>
          </>
        )}
        {success && (
          <>
            <div className="text-gray-800 mt-4 text-center w-full h-full align-middle py-[16%]">
              <UilCheckCircle className="text-green-600 w-12 h-12 mx-auto" />
              <p className="font-semibold text-lg my-2">
                İsteğiniz başarılı bir şekilde alındı!
              </p>
              <p className="font-light mt-4">
                Talebiniz başarılı bir şekilde alındı, en kısa
                sürede uzman ekibimiz sizinle iletişime geçecektir.
              </p>
              <p className="font-light mt-4 text-sm">
                Bizi tercih ettiğiniz için teşekkür ederiz 😊
              </p>
            </div>
          </>
        )}
        {error && (
          <div className="text-red-600 mt-4 font-semibold text-base">
            {errMsg}
          </div>
        )}
      </form>
    </div>
  );
};

export default Content;
