import { SVGProps } from "react";

interface ImobileMenu {
  childs: {
    name: string;
    href: string;
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  }[];
}
const mobileMenu = (props: ImobileMenu) => {
  return (
    <div className="mt-6 md:hidden">
      <nav className="grid gap-y-8">
        {props.childs.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
          >
            <item.icon
              className="flex-shrink-0 h-6 w-6 text-indigo-600"
              aria-hidden="true"
            />
            <span className="ml-3 text-base font-medium text-gray-900">
              {item.name}
            </span>
          </a>
        ))}
      </nav>
    </div>
  );
};

export default mobileMenu;
