import { SVGProps } from "react";

type sub = {
  name: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  childs: {
    name: string;
    description: string;
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    href: string;
  }[];
};
interface ItabletMenu {
  kurumsal: sub;
  products: sub;
  references: {
    name: string;
    href: string;
  };
  other: {
    name: string;
    href: string;
  };
}
const tabletMenu = (props: ItabletMenu) => {
  const menu = [props.kurumsal, props.products];

  return (
    <div className="hidden grid-cols-3 mt-4 md:grid">
      {menu.map((item) => {
        return (
          <div
            className="items-start bg-gray-50  gap-2 rounded px-1 mx-1"
            key={item.name + "tablet"}
          >
            <div className="relative gap-3  py-3 border-b font-medium px-5">
              {item.name}
            </div>
            <div className="relative gap-5py-3 sm:gap-5">
              {item.childs.map((subItem, index) => (
                <a
                  href={subItem.href}
                  key={index + "subitem"}
                  className="m-0  py-2  flex items-start  hover:bg-gray-50 border-bottom overflow-auto"
                >
                  {subItem.icon && (
                    <div className="h-10 w-10  text-center justify-center items-center rounded flex  bg-gray-50">
                      <subItem.icon
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="ml-1 pr-1">
                    <p className="text-sm font-medium text-gray-900">
                      {subItem.name}
                    </p>
                    {subItem.description && (
                      <p className="mt-1 text-xs text-gray-500">
                        {subItem.description}
                      </p>
                    )}
                  </div>
                </a>
              ))}
            </div>
              
          </div>
        );
      })}
      <a className="relative gap-3  py-3 border-b font-medium px-5" href={props.references.href}>
              {props.references.name}
            </a>
    </div>
  );
};

export default tabletMenu;
