import { UilPhoneVolume } from "@iconscout/react-unicons";
import { useRef, useState } from "react";
import Popup from "../../popup";
import BilgiFormu from "../../popup-content/bilgi-formu";

interface ITop {
  description?: string
}

const MobileHeaderTop = (props: ITop) => {


  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);



  const popUpShow = () => {
    setOpen(true);
  };

  return (
    <>

      <div className="fixed top-0 z-30 text-white  w-full border-b p-2 flex md:hidden justify-between items-center bg-[#01050e]">
        <div className="text-sm">
          Karar veremiyorsanız <br />
          <a className='' href='tel:08503466600'><UilPhoneVolume className="inline w-4 " /> 0850 346 66 00</a>
        </div>
        <div className="align-middle text-sm flex items-center">yada</div>

        <button
          className="ml-8  h-8 whitespace-nowrap inline-flex items-center justify-center p-2 border border-transparent rounded shadow-sm text-sm font-400 text-white bg-orange-600 hover:bg-orange-"
          onClick={popUpShow}
        >
          Sizi Arayalım
        </button>
      </div>
      <Popup open={open} setOpen={setOpen}>
        <BilgiFormu description={props.description} cancelButtonRef={cancelButtonRef} setOpen={setOpen} />
      </Popup>

    </>
  );
};

export default MobileHeaderTop;
