/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import Menu from "./header/menu";
import MobileMenu from "./header/mobilemenu";
import MobileLinks from "./header/mobilelinks";
import MobileFooter from "./header/mobilefooter";
import TabletMenu from "./header/tabletmenu";
import MetaTags from "./header/metatags";
import Image from "next/image";
import Link from "next/link";

import Popup from "../popup";
import DemoTalepFormu from "../popup-content/demo-talep-formu";
import MobileHeaderTop from "./header/mobileheadertop";

import {
  ChartBarIcon,
  MenuIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";

const kurumsal = {
  name: "Kurumsal",
  icon: ViewGridIcon,
  childs: [
    {
      name: "Hakkımızda",
      description: "Eticex‘i tanıyın",
      icon: ChartBarIcon,
      href: "/hakkimizda.html",
    },
    {
      name: "Bayilik",
      description: "Satış ortağımız olun",
      icon: ChartBarIcon,
      href: "/bayilik.html",
    },
    {
      name: "İletişim",
      description: "Bize ulaşın",
      icon: ShieldCheckIcon,
      href: "/iletisim.html",
    },
    // {
    //   name: 'Kariyer',
    //   description: 'Get a better under stand',
    //   icon: ViewGridIcon,
    //   href: '#',
    // }
  ],
};

const products = {
  name: "Ürünlerimiz",
  icon: ViewGridIcon,
  childs: [
    {
      name: "E-ticaret Paketini Oluştur",
      description: "1 ay süreli e-ticaret paketleri",
      icon: ChartBarIcon,
      href: "/aylik-e-ticaret-paketleri.html",
    },
    {
      name: "E-ticaret Paketleri",
      description: "1 yıl süreli e-ticaret paketleri",
      icon: ChartBarIcon,
      href: "/e-ticaret-paketleri.html",
    },
    {
      name: "E-ihracat",
      description: "Tüm dünya müşteriniz olsun",
      icon: ViewGridIcon,
      href: "/e-ihracat.html",
    },
    {
      name: "Entegrasyonlar",
      description: "Eticex‘le entegre olun",
      icon: ViewGridIcon,
      href: "/entegrasyonlar.html",
    },
    {
      name: "Özel Tasarım Hizmeti",
      description: "Size özel arayüz kodlama",
      icon: ViewGridIcon,
      href: "/ozel-tasarim-hizmeti.html",
    },
    {
      name: "Modüller",
      description: "Sektörel ve ihtiyaca yönelik modüller",
      icon: ChartBarIcon,
      href: "/moduller.html",
    }
  ],
};



const references = { name: "Referanslar", href: "/referanslar.html" };
const other = { name: "Blog", href: "/blog" };

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface IHeaderProps {
  title: string;
  description: string;
  bg?: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  topHeader?: boolean;
  topHeaderDesc?: string;
  nomenu?: boolean;
}

export default function Header(props: IHeaderProps) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);

  const popUpShow = () => {
    setOpen(true);
  };
  return (
    <>
      <MetaTags title={props.title} description={props.description} />
      {props.topHeader ? <MobileHeaderTop description={props.topHeaderDesc} /> : ''}
      <Popover
        className={classNames(
          props.bg == "dark" ? "bg-transparent" : "bg-gray-30",
          "relative"
        )}
      >
        <div className={(props.topHeader ? 'mt-12' : '') + " container mx-auto px-4  md:mt-0"}>
          <div
            className={classNames(
              props.bg == "dark" ? "" : "",
              "flex justify-between items-center  py-6 md:justify-start md:space-x-10"
            )}
          >
            <div className="flex justify-start lg:w-2/12">
              <Link href={"/"}>

                <span className="sr-only">Eticex</span>
                <Image
                  className="h-8 w-auto sm:h-10"
                  src={
                    props.bg == "dark"
                      ? "/dark-eticex-logo.svg"
                      : "/white-eticex-logo.svg"
                  }
                  alt=""
                  width="123"
                  height="40"
                  unoptimized={true}
                />

              </Link>
            </div>

            {(props.nomenu !== false &&
              <Menu
                kurumsal={kurumsal}
                products={products}
                references={references}
                other={other}
                bg={props.bg}
              />
            )}
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a href='tel:08503466600'
                className={classNames(
                  props.bg == "dark" ? "text-white" : "",
                  "whitespace-nowrap text-base font-semibold  text-black-500 hover:text-black-900"
                )}
              >
                0850 346 66 00
              </a>
              <button
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded shadow-sm text-sm font-400 text-white bg-blue-1000 hover:bg-orange-600"
                onClick={() => popUpShow()}
              >
                Ücretsiz Deneyin
              </button>
            </div>

            <div className="-my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0  transition transform origin-top-right z-30 "
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-4 md:pt-6">
                <div className="flex items-center justify-between md:space-x-10">
                  <div>
                    <Image
                      className="h-8 w-auto  sm:h-10"
                      src="/white-eticex-logo.svg"
                      alt="Workflow"
                      unoptimized={true}
                      width={123}
                      height={40}
                    />
                  </div>

                  <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <span className="whitespace-nowrap text-base font-semibold  text-black-500 hover:text-black-900">
                      0850 346 66 00
                    </span>
                    <button
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded shadow-sm text-sm font-400 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none"
                      onClick={() => popUpShow()}
                    >
                      Ücretsiz Deneyin
                    </button>
                  </div>

                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>

                <MobileMenu {...products} />
                <TabletMenu
                  kurumsal={kurumsal}
                  products={products}
                  references={references}
                  other={other}
                />
              </div>
              <MobileLinks links={kurumsal.childs} other={other} />

              <div className="md:hidden flex justify-between items-center py-6 px-5  divide-y-2 divide-gray-50">
                <MobileFooter setOpen={setOpen} />
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <Popup open={open} setOpen={setOpen}>
        <DemoTalepFormu cancelButtonRef={cancelButtonRef} setOpen={setOpen} />
      </Popup>
    </>
  );
}
