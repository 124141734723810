export const ValidateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const ValidatePhoneNumber = (phoneNumber: string) => {
  const phoneno10 = /^\d{10}$/;
  const phoneno11 = /^\d{11}$/;
  const number = phoneNumber.replace(/ /g,'');

  if (number.match(phoneno10) && number.startsWith("0") !== true) {
    return true;
  } else if(number.match(phoneno11) && number.startsWith("0") === true) {
    return true;
  }else{
    return false;
  }
};
